<template>
  <div id="main"
       v-loading="loading"
       :element-loading-text="loadingText"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"
       element-loading-font-size="28px"
  >
    <div class="hd">
      <div class="hd-header">模型训练</div>
      <div class="hd-button-group">
        <div class="radio">
          <el-radio v-model="radio" label="1">决策树(tree)</el-radio>
          <el-radio v-model="radio" label="2">向量机(svm)</el-radio>
        </div>
        <el-upload
            ref='upload'
            class="upload"
            :action="uploadObj.url"
            :on-success="handleSuccess"
            :on-error="handleError"
            :limit="3"
            :show-file-list=false
        >
          <el-button size="small" type="primary">训练上传数据</el-button>
        </el-upload>
        <el-button type="primary" style="background-color:#007bff" @click="startTrain">开始训练</el-button>
      </div>
    </div>
    <div class="tb">

      <div class="tb-score">模型准确率: <span style="color: rgb(0, 123, 255)"> {{score}}</span></div>
      <div class="tb-ret" style="white-space: pre-wrap;" v-show="ret_show">{{ret}}</div>
<!--      <div class="block" style="width: 350px;height: 200px">-->
<!--        <el-image-->
<!--            style="width: 100%; height: 100%"-->
<!--            :src="imageUrl"-->
<!--            :preview-src-list="[imageUrl]">-->
<!--        </el-image>-->
<!--      </div>-->
    </div>




    <div class="hd second">
      <div class="hd-header">预测</div>
      <div class="hd-button-group">
        <div class="radio">
          <el-radio v-model="radio2" label="1">决策树(tree)</el-radio>
          <el-radio v-model="radio2" label="2">向量机(svm)</el-radio>
        </div>
        <el-upload
            class="upload"
            :action="uploadObj2.url"
            :data="uploadObj2.param"
            ref='upload'
            :on-success="handleSuccess"
            :limit="3"
            :show-file-list=false
        >
          <el-button size="small" type="primary">测试样本上传</el-button>
        </el-upload>
        <el-button type="primary" style="background-color:#007bff" @click="startPredict">开始预测</el-button>
      </div>
    </div>
    <div class="tb2">
      <div class="tb-item res" style="margin-bottom: 15px">
        预测结果:
      </div>
      <div class="tb-item res-res" >
        <template v-for="(x,k) in res">

          <div :key="k">{{x}}</div>

        </template>

      </div>

    </div>
    <div class="save_data">
      <el-button class="save_data-item"  type="primary" style="background-color:#007bff" @click="savePredict">保存预测结果</el-button>
    </div>
  </div>


</template>

<script>
import FileSaver  from 'file-saver';
import {baseURL} from "../assets/api/http.js";
export default {
  name: "index",
  data() {
    return {
      loading:false,
      radio:"1",
      radio2:"1",
      imageUrl:"data:image/png;base64,",
      uploadObj:{
        url:baseURL+"/upload",

      },
      uploadObj2:{
        url:baseURL+"/upload",
        param:{
          "file_type":"test"
        }
      },
      fileList:[],
      score:"",
      ret:"",
      res:"",
      ret_show:false,
      loadingText:""

    }
  },
  methods:{
    handleSuccess(res){
      console.log(res)
      this.$message.success("上传成功!");
      this.$refs.upload.clearFiles();
    },
    handleError(res){
      console.log(res)
      this.$message.error("上传失败!");
    },
    startTrain(){
      this.loadingText="样本预测中请等待!"
      this.loading = true;
      console.log(this.radio)
      let params={}
      if (this.radio=="1"){
        params={"models":"tree"}
      }else{
        params={"models":"svm"}
      }
      this.$apiFun.train(params).then(res=>{
        if (res.code===200) {

            this.score=res.datas.score+"%"
            this.ret_show=true
            this.ret=res.datas.ret
            this.loading = false;

        }
      })
    },
    startPredict(){
      this.loadingText="样本预测中请等待!"
      this.loading = true;
      let params={}
      if (this.radio2=="1"){
        params={"models":"tree"}
      }else{
        params={"models":"svm"}
      }
      this.$apiFun.predict(params).then(res=>{
        if (res.code===200) {
            this.res=res.datas
            this.loading = false;
        }
        else{
          this.$message.error("请先上传测试样本!");
        }
      })
    },
    savePredict(){
      let data={}
      for (let i=0;i<=this.res.length;i++){

          data= Object.assign({}, data, this.res[i])
      }
      var blob = new Blob([JSON.stringify(data)], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, "Predict.json");

    }


  },
  mounted() {
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 2 * 1000);
  }

}
</script>

<style scoped lang="less">
#main{
  width: 100%;
  height: 100%;
  background-color: rgb(255,255,255);
  border-radius: 5px;

  .hd{
    border-bottom: 1px solid  #f0f0f0;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .hd-header{
      display: flex;
      align-content: center;
      line-height: 40px;
    }
    .hd-button-group{
      display: flex;
      flex-direction: row;
      .radio{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 35px;
      }
      .el-button{
        background-color: rgb(0, 123, 255);
        margin-right: 2em;
        width: 98px !important;
        height: 40px !important;
      }
    }

  }
  .tb{
    height: 200px;
    padding: 45px 25px;
    display: flex;
    flex-direction: column;

    align-items: center;
    .tb-score{
      margin-bottom: 15px;

    }
    .tb-ret{
      width: 400px;
      height: 600px;
      padding-top: 15px;
      padding-bottom: 15px;
      background: black;
      color: white;
    }


  }

  .res{
    margin-top: 65px;

  }
  .res-res{
    margin: 0 auto;
    margin-top: 35px;
    width: 80%;
    height: 200px;
    color: red;
    overflow-y: scroll;
  }
  .second{
    margin-top: 15px;
  }
  .save_data{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .save_data-item{
      margin-top: 35px;
      margin-right: 50px;
    }

  }
  .el-loading-mask .el-loading-spinner .el-loading-text {
    font-size: 28px;
  }
}
</style>


