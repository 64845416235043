<template>
  <div id="main">
    <div id="hd">
      <div id="hd-header">模型列表</div>

    </div>
    <div id="tb">
      <el-table
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{textAlign: 'center','color':'black'}"
          :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column
            type="index"
            :index="indexAdd"
            label="序号"
            width="60px"
            align="center"
        >
          <template slot-scope="scop">
            {{scop.$index+1}}
          </template>
        </el-table-column>
        <el-table-column v-for="item in tableHeaders" :prop="item.prop" :label="item.label" :key="item.prop" :width="item.width"></el-table-column>

        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="downloadfile(scope.$index, scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>


</template>

<script>
import {baseURL} from "../assets/api/http.js";
export default {
  name: "index",
  data() {
    return {
      tableHeaders:[
        {
          prop:"models",
          label:"类型"
        },
        {
          prop:"name",
          label:"文件名"
        },
        {
          prop:"fileSize",
          label:"文件大小"
        },


      ],
      tableData: [
        // {
        //   "models":"决策树",
        //   "name":"tree.pkl",
        //   "fileSize":"18kb",
        // },
        // {
        //   "models":"svm",
        //   "name":"svm.pkl",
        //   "fileSize":"18kb",
        // }

      ],
      input: ''
    }
  },
  methods:{
    btn(){
      this.page=1
      this.currentPage=1
    },
    //模型列表
    modelsList(){

      this.$apiFun.modelsList().then(res=>{
        if(res.code===200){
          this.tableData=res.datas

        }
      })
    },
    //获取进程列表
    downloadfile(index, row){
      console.log(baseURL)
      console.log(index,row)
      window.location.href=baseURL+`/downloadfile?filename=${row.name}`
      // this.$apiFun.downloadfile({"filename":row.name}).then(res=>{
      //   if(res.code===200){
      //     this.tableData=res.datas
      //
      //   }
      // })
     },

  },
  mounted() {
    this.modelsList()
  }

}
</script>

<style scoped lang="less">
#main{
  width: 100%;
  height: 100%;
  background-color: rgb(255,255,255);
  border-radius: 5px;
  #main-cnt{
  }
  #hd{
    border-bottom: 1px solid  #f0f0f0;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    #hd-header{
      display: flex;
      align-content: center;
      line-height: 40px;
    }
    #hd-button-group{
      display: flex;
      justify-content: space-between;
      .el-input{
        margin-left: 8px;
        width: 200px;
        /deep/ .el-input__suffix{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto 0;
        }
        .el-input__icon{
          background-color: #3967FF;
        }
      }
    }

  }
  #tb{
    padding: 25px 25px;
    .el-button{
      background-color: #007bff;
      color: white;
    }
  }
  #ft{
    padding: 15px 15px;
    border-bottom: 1px solid #DCDCDC;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(0,0,0,.03);
  }
}
</style>


<!--<template>-->
<!--  <div id="china_map_box">-->
<!--    <div id="china_map"></div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import * as echarts from 'echarts'-->
<!--import  '../assets/js/china.js'-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--    };-->
<!--  },-->
<!--  methods: {-->
<!--    //初始化中国地图-->
<!--    initEchartMap() {-->
<!--      let mapDiv = document.getElementById('china_map');-->
<!--      //初始化echarts实例 通过传参获取要放置的id名称-->
<!--      var myChart = echarts.init(mapDiv);-->
<!--      // 指定图表的配置项和数据-->
<!--      var optionMap = {-->
<!--        //设置 背景颜色-->
<!--        title:{-->
<!--          text:"中国地图",-->
<!--          left: 'center',-->
<!--          color:"white"-->
<!--        },-->
<!--        backgroundColor: "#f0f0f0",-->
<!--        tooltip: {-->
<!--          trigger: "item",-->
<!--        },-->

<!--        //左侧小导航图标-->
<!--        // visualMap: {-->
<!--        //   // 水平摆放-->
<!--        //   orient:"horizontal",-->
<!--        //   // 是否显示指示条-->
<!--        //   show: true,-->
<!--        //   // 设置字体-->
<!--        //   textStyle: {-->
<!--        //     fontSize: 3.5,-->
<!--        //   },-->
<!--        //   // 显示的位置-->
<!--        //   x: "",-->
<!--        //   splitList: [-->
<!--        //     { start: 0, end: 0 },-->
<!--        //     { start: 1, end: 9 },-->
<!--        //     { start: 10, end: 99 },-->
<!--        //     { start: 100, end: 499 },-->
<!--        //     { start: 500, end: 999 },-->
<!--        //     { start: 1000, end: 10000 },-->
<!--        //     { start: 10000 },-->
<!--        //   ],-->
<!--        //   color: [-->
<!--        //     "#4a1213",-->
<!--        //     "#772526",-->
<!--        //     "#bb3937",-->
<!--        //     "#d56355",-->
<!--        //     "#e9a188",-->
<!--        //     "#faebd2",-->
<!--        //     "#f5f5f5",-->
<!--        //   ],-->
<!--        //-->
<!--        // },-->


<!--        //配置属性-->
<!--        series: [-->
<!--          {-->
<!--            name: "title",-->
<!--            type: "map",  // 类型 为map地图-->
<!--            // 具体地图类型为china 在这里需要引入echarts中自带的China.js文件-->
<!--            mapType: "china",-->
<!--            roam: false, // 设置是否可以缩放 false 为不可以缩放-->
<!--            zoom:1.2,// 默认缩放比例  刚进入页面时的缩放比例-->
<!--            itemStyle:{-->
<!--              normal: {-->
<!--                areaColor: '#0C1156',-->
<!--                borderColor: 'skyblue',-->
<!--                borderWidth: 1,-->
<!--                // shadowColor: '#ccc',-->
<!--                shadowBlur: 30,-->
<!--                opacity:1-->
<!--              },-->
<!--            },-->
<!--            label: {-->
<!--              normal: {-->
<!--                show: true,-->
<!--                textStyle: {-->
<!--                  fontSize: 12,-->
<!--                  color: "white"-->
<!--                },-->
<!--              },-->
<!--              emphasis: {-->
<!--                show: true,-->
<!--              },-->
<!--            },-->
<!--            data:[{name:'xxx',value:"数字"},]-->
<!--          },-->
<!--        ],-->
<!--        grid: {-->
<!--          x: 1,-->
<!--          y: 1,-->
<!--          x2: 1,-->
<!--          y2: 1-->
<!--        },-->
<!--      };-->
<!--      //使用制定的配置项和数据显示图表-->
<!--      myChart.setOption(optionMap);-->
<!--    },-->

<!--  },-->
<!--  mounted() {-->
<!--    this.$nextTick(()=>{-->
<!--      this.initEchartMap();-->
<!--    })-->

<!--  }-->
<!--};-->
<!--</script>-->

<!--<style scoped>-->
<!--#china_map_box {-->
<!--  width: 50%;-->
<!--  height: 80%;-->
<!--  margin: 0 auto;-->

<!--}-->
<!-- #china_map{-->
<!--  height: 100%;-->
<!--}-->
<!--/*#china_map_box .china_map_logo{*/-->
<!--/*  position: absolute;*/-->
<!--/*  top: 0;*/-->
<!--/*  left: 0;*/-->
<!--/*  width:45px;*/-->
<!--/*}*/-->
<!--</style>-->
<!--<style>-->
<!--#china_map .tooltip_style{-->
<!--  line-height:1.7;-->
<!--  overflow: hidden;-->
<!--}-->
<!--/*#china_map .tooltip_left{*/-->
<!--/*  float: left;*/-->
<!--/*}*/-->
<!--/*#china_map .tooltip_right{*/-->
<!--/*  float: right;*/-->
<!--/*}*/-->

<!--</style>-->
