<template>
  <div class="cont">
    <div class="cont-item el-icon-menu" >
    </div>
    <div class="cont-item cont-item-foot">
      <img :src="headImg" alt="">
      <div id="skname">{{this.username}}</div>

      <el-dropdown>
        <i class="el-icon-setting"  style="font-size: 20px;height: 60px;line-height: 60px"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="editPasswd">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="sysyExit">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dialog
          :visible.sync="dialogVisible"
          width="30%"
          >
        <div>
          <div class="oldPasswd">
            <div class="header">原密码: </div><el-input ></el-input>
          </div >
          <div class="newPasswd">
            <div class="header">新密码: </div><el-input ></el-input>
          </div>
          <div class="newPasswd">
            <div class="header">新密码: </div><el-input ></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>

    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data(){
    return{
      headImg:require("../assets/img/1.jpg"),
      dialogVisible:false,
      username:""
    }
  },
  methods:{
    sysyExit(){
      this.$router.push("/login")
      sessionStorage.removeItem("username")
    },
    editPasswd(){
      this.dialogVisible=true
    },
    init(){
      this.username=sessionStorage.getItem("username")
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped lang="less">
  .cont{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color:rgb(255,255,255);
    background-color:#DCDCDC;
    background-color:white;
    border-bottom: 1px solid #DCDCDC;
    border-bottom: 1px solid #dee2e6;
    .cont-item{
      width: 50px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      color: black;
    }
    .cont-item:hover{
      cursor: pointer;
    }
    .cont-item-foot{
      width: 300px;
      display: flex;
      justify-content: center;
      img{
        width: 40px;
        height: 60%;
        border-radius: 30px;
        margin:auto 0;
      }
      #skname{
        margin:auto 0;
        margin-left: 1rem;
        font-size: 1rem;
      }
      .el-icon-setting{
        margin-left: 15px;
      }
    }
    .oldPasswd{
      display: flex;
      flex-direction: row;
      .header{
        margin-right: 5px;
        line-height: 40px;
      }
      .el-input{
        width: 85%;
      }
    }
    .newPasswd{
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      .header{
        margin-right: 5px;
        line-height: 40px;
      }
      .el-input{
        width: 85%;
      }
    }
  }
</style>