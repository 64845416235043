<template>
  <el-row class="tac">
    <el-col >
      <el-menu
          :default-active="$route.path"
          class="el-menu-vertical-demo"

          background-color= #343a40
          text-color="#fff"
          active-text-color="#fff"
          router
          unique-opened
      >
        <template v-for="item in routerList">
          <el-submenu v-if="item.children && item.children.length"  :index="item.path" :key="item.path">
            <template slot="title" class="elm_tile">
              <i :class="item.icon"></i>
              <span>{{item.name}}</span>
            </template>
            <el-menu-item :index="item2.path"  class="menu-item" v-for="item2 in item.children" :key="item2.path">
              <i :class="item2.icon" class="icon"></i>
              <span>{{item2.name}}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :index="item.path" :key="item.path" >
            <i :class="item.icon"></i>
            <span slot="title">{{item.name}}</span>
          </el-menu-item>

        </template>
      </el-menu>
    </el-col>
    </el-row>
</template>

<script>
import "../assets/aliicon/iconfont.css"
export default {
  name: 'Aside',
  props: {
    msg: String
  },
  data(){
    return{
      routerList:[

        {
          name:"模型训练",
          path: "/home",
          icon:"seaniconfont seanicondiannao",
        },
        {
          name:"模型下载",
          path: "/modelList",
          icon:"seaniconfont seaniconchakanjincheng",
        },

      ]
    }
  },
}
</script>

<style scoped lang="less">
  .tac{
    outline:0;
    width: 100%;
    text-align: left;
    background-color: #343a40;
    el-menu-item-group{
      width: 100%;
    }
    .el-menu{
      border-right:none;
    }
    .el-menu-item.is-active {
      background-color: rgb(76,132,255) !important;
    }
    .el-submenu /deep/ .el-submenu__title:hover, .el-menu-item:hover{
      background-color: #ffd04b !important;
    }
    //.el-submenu /deep/ .el-menu-item, .menu-item{
    //  text-align: left;
    //  //margin-right: 10px;
    //  padding-left: 25px !important;
    //}
    .seaniconfont
    {
      padding-right: 20px;
    }

  }
</style>
