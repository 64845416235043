/* eslint-disable */
<template>
  <div id="main">
<!--    <img :src="cat" alt="">-->

    <div id="from">
      <div id="header">分布式系统故障诊断系统</div>
      <el-form    label-width="100px" class="demo-ruleForm">
          <el-input class="input" v-model="username" prefix-icon="iconfont icon-yonghu" type="text" placeholder="请输入用户名"></el-input>
          <el-input class="input" v-model="passwd" prefix-icon="iconfont icon-mima1" type="password"  placeholder="请输入密码"></el-input>
      </el-form>

      <div id="btn">
        <el-button  id="btnm" type="primary" @click="userLogin">登录</el-button>
      </div>

    </div>

  </div>
</template>

<script>


export default {
  name: "login",
  data () {
    return {
      username:"",
      passwd:"",
    }
  },
  methods:{
    userLogin(){
      if(this.username==""){
        this.$message.error("账号不能为空!");
        return
      }
      if(this.passwd==""){
        this.$message.error("密码不能为空!");
        return
      }
      let params={"username":this.username,"passwd":this.passwd}
      this.$apiFun.login(params).then(res=>{
        if( res.code == 200 ) {
          sessionStorage.setItem('username',this.username)
          this.$router.push("/")
        }else{
          this.$message.error("账号或密码错误!");
        }
      }).catch(err=>{
        console.log(err)
        this.$message.error("账号或密码错误!");
      })
    }
  }
}
</script>

<style scoped lang="less">
#main{
  width: 100%;
  height: 100%;
  //background-color: rgb(37,190,180);
  background-image: url('../assets/img/bg_yuan.png');
  display: flex;
  justify-content: flex-end;
  align-items: center;
  #from{
    margin-right: 8vw;
    border-radius: 5px;
    padding: 30px;
    width: 300px;
    height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    #header{
      margin-bottom: 1.8em;
      font-size: 1.2em;
    }
    .input{
      margin-bottom: 40px;

    }
    #btn{
      width: 100%;
      #btnm{
        width: 100%;
        border: none;
        background-image: linear-gradient(to right, #EB8355 0%, #D74F2C 100%);
      }
    }

  }
}
</style>