<template>
  <el-container id="cont">
<!--    <el-header id="cont_header">-->
<!--      <Header></Header>-->
<!--    </el-header>-->
    <el-aside id="cont_aside">
      <div id="cont_aside-header" class="">
        <span id="img"></span>
        <div id="font">故障诊断系统</div>

      </div>
      <Aside></Aside>
    </el-aside>
    <el-main id="cont_main">
      <Header id="cont_main-header"></Header>
      <div id="cont_main-item">
        <router-view/>
      </div>
    </el-main>

  </el-container>
</template>

<script>
import Aside from '@/components/Aside.vue'
import Header from '@/components/Header.vue'
 export default {
  components:{
    Aside,
    Header
  },
   data(){
      return{
        username:""
      }
   },
   methods:{
      init(){
        this.username=this.$store.state.username
      }
   },
   mounted() {

   }
 }
</script>

<style scoped lang="less">
#cont{
  width: 100%;
  height: 100%;
  background-color: red;
  #cont_aside{
      width: 200px !important;
      height: 100%;
      background-color: #343a40;
      #cont_aside-header{
        width: 200px;
        height: 60px;
        color: #ffffff;
        font-size: 24px;
        line-height: 60px;
        border-bottom: 1px solid #4b545c;
        display: flex;
        flex-direction: row;
        #img{
          margin-left: 15px;
          display: block;
          width: 50px;
          height: 100%;
          background: url("../assets/img/mon.png")  no-repeat  center 0px;
          background-size: cover;
          background-position: center 0px;
        }
        #font{
          margin-left: 5px;
          height: 100%;
          display: block;
          vertical-align:middle;
          margin-top: 0;
          font-size: 20px;
        }

      }

  }
  #cont_main{
      padding: 0;
      height: 100%;
      background-color:#DCDCDC;
      #cont_main-header{
        height: 7.5%;
      }
      #cont_main-item{
        width: 100%;
        height: 92%;
        box-sizing: border-box;
        padding: 15px;

      }

  }

}


</style>